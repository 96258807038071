// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cenovnik-js": () => import("./../../../src/pages/cenovnik.js" /* webpackChunkName: "component---src-pages-cenovnik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-prodaja-klima-js": () => import("./../../../src/pages/prodaja-klima.js" /* webpackChunkName: "component---src-pages-prodaja-klima-js" */),
  "component---src-pages-usluge-js": () => import("./../../../src/pages/usluge.js" /* webpackChunkName: "component---src-pages-usluge-js" */)
}

